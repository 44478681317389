<template>
  <div>
    <div
      v-for="(r , i) in rows"
      :key="i"
      class="row mb-2 border row align-items-center "
    >
      <div class="col-lg-2 col-md-2 d-grid text-center">
        <center>
          <img
            :src="r.firmLogo === '' ? require('@/assets/images/firmnoImage.png') : r.firmLogo"
            class="img-fluid"
            width="50"
            heigt="50"
          >
          <br><br>
        </center>
      </div>
      <div class="col-lg-4 col-md-4 row align-items-center mt-1">
        <table class="table table-bordered table-sm">
          <tbody>
            <tr>
              <th class="text-danger">
                Firma Unvanı:
              </th>
              <td class="text-center">
                {{ r.firmTitle }}
              </td>
            </tr>
            <tr>
              <th class="text-danger">
                Faaliyet Alanı:
              </th>
              <td class="text-center">
                {{ r.firmActivityArea }}
              </td>
            </tr>
            <tr>
              <th class="text-danger">
                Web Sitesi:
              </th>
              <td class="text-center">
                {{ r.firmWebSite }}
              </td>
            </tr>
            <tr>
              <th class="text-danger">
                Firma Telefon:
              </th>
              <td class="text-center">
                {{ r.firmTelephone1 }}
              </td>
            </tr>
            <tr>
              <th class="text-danger">
                Firma E-Posta:
              </th>
              <td class="text-center">
                {{ r.firmEmail }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="col-lg-4 col-md-4 row align-items-center mt-1">
        <table class="table table-bordered table-sm">
          <tbody>
            <tr>
              <th class="text-danger">
                Eksik Kayıtlar
              </th>
              <td class="text-center">
                <b-col>
                  <b-row
                    v-for="(ey,y) in cevir(r)"
                    :key="y"
                    class="pl-1"
                  >
                    {{ ey }}
                  </b-row>
                </b-col>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="col-lg-2 col-md-12 inline-blocks text-center">
        <center>
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            size="sm"
            squared
            class="mr-1 mb-1"
            variant="relief-primary"
            @click="onGetFirmClicked(r)"
          >
            <feather-icon
              icon="EditIcon"
              class="mr-30"
            />
            <span>Firma Envanter</span>
          </b-button>
        </center>
      </div>
    </div>
  </div>
</template>
<script>
import {
  BButton, VBModal, BCol, BRow,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import * as apiFirma from '@/api/islemler/firminventory'

export default {
  components: {
    BButton, BCol, BRow,

  },
  directives: {
    Ripple,
    'b-modal': VBModal,
  },
  data() {
    return {
      rows: [],
    }
  },
  computed: {

  },
  created() {
    this.getSummary()
  },
  mounted() {

  },
  methods: {
    fieldfn(rowObj) {
      return `<img width="80" heigt="80" src="${rowObj.firmLogo}" />`
    },
    getSummary() {
      apiFirma.GetIncompletedFirmsAll().then(res => {
        this.rows = res.data.list
      })
    },
    getImg(row) {
      return `${row.firmLogo}`
    },
    clearModal() {
    },
    onDataGridItemClicked(params) {
      return params
    },
    onUpdateBtnClicked() {
    },
    onGetFirmClicked(row) {
      this.$router.push({ name: 'firma-kart', params: { firmId: row.firmInfoId } })
    },
    cevir(row) {
      let problems = []
      const retvals = []
      let say = ''
      if (row.validationErrors === null) return ''
      problems = row.validationErrors.split(',')
      problems.forEach((value, i) => {
        say = `${i.toString()} adet eksik giriş`
        switch (value) {
          case 'FirmTitle': retvals.push('Firma Ünvanı')
            break
          case 'FirmSectors': retvals.push('Firma Sektörler')
            break
          case 'FirmTelephone1': retvals.push('Telefon 1')
            break
          case 'FirmTelephone2': retvals.push('Telefon 2')
            break
          case 'FirmEmail': retvals.push('E-Posta')
            break
          case 'ResponsiblePerson1': retvals.push('Görüşülen Kişi 1')
            break
          case 'ResponsiblePerson1Task': retvals.push('Görüşülen Kişi 1 Görevi')
            break
          case 'ResponsiblePerson1PhoneNumber': retvals.push('Görüşülen Kişi 1 Telefon')
            break
          case 'ResponsiblePerson1EMail': retvals.push('Görüşülen Kişi 1 E-posta')
            break
          case 'ResponsiblePerson2': retvals.push('Görüşülen Kişi 2')
            break
          case 'ResponsiblePerson2Task': retvals.push('Görüşülen Kişi 2 Görevi')
            break
          case 'ResponsiblePerson2PhoneNumber': retvals.push('Görüşülen Kişi 2 Telefon')
            break
          case 'ResponsiblePerson2EMail': retvals.push('Görüşülen Kişi 2 E-POsta')
            break
          default:
            // retvals.push(value)
            break
        }
      })
      if (retvals.length === 0) {
        say = 'Eksik yoktur'
      } else {
        say = `${retvals.length} adet kayıt eksik`
      }
      retvals.push(say)
      return retvals // .join('\r\n ')
    },
  },
}
</script>
<style lang='scss'>
   @import '@core/scss/vue/libs/vue-good-table.scss';
</style>
